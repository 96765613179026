export default {
  data() {
    return {
      // 查询专用
      queryData: {},
      // 车间
      queryWorkshopList: [],
      // 仓库
      queryWarehouseList: [],
      // 区域
      queryAreaList: [],
      // 产线
      queryProductionLineList: [],
      // 库区
      queryWarehouseAreaList: [],
      // 库位
      queryFactoryPositionList: [],
      // 设备
      queryDeviceList: [],
      // 设备货位
      queryGoodsPositionList: []
    }
  },
  watch: {
    formModel: {
      handler() {
        this.$nextTick(() => {
          this.$refs.myQueryView?.$refs?.moreQueryData?.changeFormValue(
            this.formModel ?? {}
          )
        })
      },
      deep: true
    }
  },
  methods: {
    resetQueryList() {
      this.queryWorkshopList = this.queryData.queryWorkshopList
      this.queryWarehouseList = this.queryData.queryWarehouseList
      this.queryAreaList = this.queryData.queryAreaList
      this.queryProductionLineList = this.queryData.queryProductionLineList
      this.queryWarehouseAreaList = this.queryData.queryWarehouseAreaList
      this.queryFactoryPositionList = this.queryData.queryFactoryPositionList
      this.queryDeviceList = this.queryData.queryDeviceList
      this.queryGoodsPositionList = this.queryData.queryGoodsPositionList
    },
    // 切换工厂、仓库、车间、库区、库位、货位
    handleChange(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
      if (formItem.name === 'factoryId') {
        this.queryWorkshopList = []
        this.queryWarehouseList = []
        this.queryAreaList = []
        this.queryProductionLineList = []
        this.queryWarehouseAreaList = []
        this.queryFactoryPositionList = []
        this.queryDeviceList = []
        this.queryGoodsPositionList = []
        const params = {
          factoryId: val
        }
        if (val) {
          this.getBaseData(
            'getWarehouseDictList',
            'queryWarehouseList',
            'warehouseCode',
            params
          )
          this.getBaseData('getWorkshopDictList', 'queryWorkshopList', 'workshopCode', params)
        } else {
          this.resetQueryList()
        }
        this.formModel.warehouseId = ''
        this.formModel.workshopId = ''
        this.formModel.areaId = ''
        this.formModel.productionLineId = ''
        this.formModel.warehouseAreaId = ''
        this.formModel.factoryPositionId = ''
        this.formModel.deviceId = ''
        this.formModel.goodsPositionId = ''
      } else if (formItem.name === 'warehouseId') {
        this.queryWarehouseAreaList = []
        this.queryFactoryPositionList = []
        // this.queryGoodsPositionList = []
        const params = {
          warehouseId: val
        }
        if (val) {
          this.getBaseData(
            'getWarehouseAreaDictList',
            'queryWarehouseAreaList',
            'warehouseAreaCode',
            params
          )
        } else {
          if (!this.formModel.factoryId) {
            this.resetQueryList()
          }
        }
        this.formModel.areaId = ''
        this.formModel.productionLineId = ''
        this.formModel.warehouseAreaId = ''
        this.formModel.factoryPositionId = ''
        this.formModel.deviceId = ''
        this.formModel.goodsPositionId = ''
      } else if (formItem.name === 'workshopId') {
        this.queryAreaList = []
        this.queryProductionLineList = []
        this.queryWarehouseAreaList = []
        this.queryFactoryPositionList = []
        this.queryDeviceList = []
        this.queryGoodsPositionList = []
        const params = {
          workshopId: val
        }
        if (val) {
          this.getBaseData(
            'getWarehouseAreaDictList',
            'queryWarehouseAreaList',
            'warehouseAreaCode',
            params
          )
          this.getBaseData(
            'getAreaDictList',
            'queryAreaList',
            'areaCode',
            params
          )
        } else {
          if (!this.formModel.factoryId) {
            this.resetQueryList()
          }
        }
        this.formModel.areaId = ''
        this.formModel.productionLineId = ''
        this.formModel.warehouseAreaId = ''
        this.formModel.factoryPositionId = ''
        this.formModel.deviceId = ''
        this.formModel.goodsPositionId = ''
      } else if (formItem.name === 'warehouseAreaId') {
        this.queryFactoryPositionList = []
        // this.queryGoodsPositionList = []
        const params = {
          warehouseAreaId: val
        }
        if (val) {
          this.getBaseData(
            'getFactoryPositionDictList',
            'queryFactoryPositionList',
            'factoryPositionCode',
            params
          )
        } else {
          if (!this.formModel.factoryId && !this.formModel.warehouseId &&
           !this.formModel.workshopId) {
            this.resetQueryList()
          }
        }
        this.formModel.factoryPositionId = ''
        // this.formModel.goodsPositionId = ''
      } else if (formItem.name === 'areaId') {
        this.queryProductionLineList = []
        this.queryDeviceList = []
        this.queryGoodsPositionList = []
        const params = {
          areaId: val
        }
        if (val) {
          this.getBaseData(
            'getProductionLineDictList',
            'queryProductionLineList',
            'productionLineCode',
            params
          )
        } else {
          if (!this.formModel.factoryId && !this.formModel.warehouseId &&
           !this.formModel.workshopId) {
            this.resetQueryList()
          }
        }
        this.formModel.productionLineId = ''
        this.formModel.deviceId = ''
        this.formModel.goodsPositionId = ''
      } else if (formItem.name === 'productionLineId') {
        this.queryDeviceList = []
        this.queryGoodsPositionList = []
        const params = {
          productionLineId: val
        }
        if (val) {
          this.getBaseData(
            'getDeviceDictList',
            'queryDeviceList',
            'deviceCode',
            params
          )
        } else {
          if (!this.formModel.factoryId && !this.formModel.workshopId &&
           !this.formModel.areaId) {
            this.resetQueryList()
          }
        }
        this.formModel.deviceId = ''
        this.formModel.goodsPositionId = ''
      } else if (formItem.name === 'deviceId') {
        this.queryGoodsPositionList = []
        const params = {
          deviceId: val
        }
        if (val) {
          this.getBaseData(
            'getGoodsPositionListByType',
            'queryGoodsPositionList',
            'goodsPositionCode',
            params
          )
        } else {
          if (!this.formModel.factoryId && !this.formModel.workshopId &&
           !this.formModel.areaId && !this.formModel.productionLineId) {
            this.resetQueryList()
          }
        }
        this.formModel.goodsPositionId = ''
        // console.log('queryGoodsPositionList', this.formModel, this.queryGoodsPositionList)
      }
      // console.log(this.formModel)
    },
    async getBaseData(url, array, label, params = {}) {
      const { data } = await this.$httpService(this.$apiStore.base(url), params)
      const list = data.map((item) => ({
        label: label === 'factoryPositionCode' ? item[label] : item.code,
        value: item.id,
        ...item
      }))
      this[array] = list
      switch (array) {
        case 'workshopList':
          this.queryWorkshopList = list
          this.queryData.queryWorkshopList = list
          break
        case 'warehouseList':
          this.queryWarehouseList = list
          this.queryData.queryWarehouseList = list
          break
        case 'warehouseAreaList':
          this.queryWarehouseAreaList = list
          this.queryData.queryWarehouseAreaList = list
          break
        case 'factoryPositionList':
          this.queryFactoryPositionList = list
          this.$store.dispatch('moreData/addFpCodeList', list)
          this.queryData.queryFactoryPositionList = list
          break
        case 'areaList':
          this.queryAreaList = list
          this.queryData.queryAreaList = list
          break
        case 'productionLineList':
          this.queryProductionLineList = list
          this.queryData.queryProductionLineList = list
          break
        case 'deviceList':
          this.queryDeviceList = list
          this.queryData.queryDeviceList = list
          break
        case 'goodsPositionList':
          this.queryGoodsPositionList = list
          this.$store.dispatch('moreData/addGoodsPositionList', list)
          this.queryData.queryGoodsPositionList = list
          break
        default:
          break
      }
      // console.log(this[array], array)
    }
  }
}
